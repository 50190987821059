html {
   height: 100%;
   font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: lighter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   min-height: 100%;
}

#root {
  min-height: 100%;
}

a {
  color: #35beff;
  text-decoration: none;
}
a:hover {
  opacity: 0.6;
  text-decoration: underline;
}

button {
  background: #333;
  color: white;
  border: solid 1px #3e3e3e;
  border-radius: 2px;
  padding: 0.5rem 1rem;
  font-size: 2rem;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  transition-duration: 0.5s;
  opacity: 0.6;
}

button:hover {
  background: #444;
  opacity: 0.8;
}